import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../components/layout"
import Header from "../../components/header"
import Content from "../../components/content"
import Footer from "../../components/footer"
import SEO from "../../components/seo"
import NewsAll from '../../components/news-all'
import Img from "gatsby-image"

const ArchivePage = ({data}) => {
    return (
        <Layout>
            <SEO title="Water Information" />
            <div className="main">
                <Header />
                <div className="main-content-container">
                <div className="main-content">
                    <div className="title-section">
                        <h1 className="title is-size-1">Water Information</h1>
                        <h3 className="title is-size-3">Check out the latest information on your water service.</h3>
                    </div>

                    <div style={{marginTop: '4rem'}} className="news-container">
                        <NewsAll posts={data.graphAPI.blogPosts} />
                    </div>

                    <div className="title-section">
                        <h3 className="title is-size-2">Additional Resources</h3>
                        <Link to="/smart-meters" className="button is-primary is-large">Smart Meters</Link>&nbsp;&nbsp; <a 
                        href="/static/6687a6f5d2778440617fdfba806cd1d4/CCR_2020_Fort_Bend_Co_MUD_23_2e911b53cc.pdf" className="button is-primary is-large">Drinking Water Quality Report</a>

                    </div>
                    
                </div>
                <Footer />
                </div>
            </div>
        </Layout>
    )
}

export default ArchivePage

export const archivePageQuery = graphql`
    query {
        graphAPI {
            blogPosts(sort: "publishedAt:desc", where: { tags:{ title: "Water" } }) {
                id
                title
                description
                publishedAt
                expiresAt
                heroImage {
                    id
                    url
                    updated_at
                    documentFile {
                        childImageSharp {
                            fluid(maxWidth: 2048){
                                ...GatsbyImageSharpFluid_tracedSVG
                            }
                        }
                    }
                }
            }
        } 
    }
`


